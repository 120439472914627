import BlockGatewayInterface from '../../Block/BlockGatewayInterface';

export default class GetValidationForBlockUseCase
{
  public blockGateway

  constructor(BlockGateway: BlockGatewayInterface) {
    this.blockGateway = BlockGateway
  }

  async execute(blockId: string): Promise<{state: string, notes: string}> {
    return await this.blockGateway.getStateValidation(blockId)
  }
}
