import FormGatewayInterface from '../../Form/FormGatewayInterface';
import ConvertNumberToWordsInterface from '../ConvertNumberToWordsInterface';

export default class ConvertNumberToWordsUseCase {
  public formGatewayInterface
  public convertNumberToWords

  constructor(FormGatewayInterface: FormGatewayInterface, ConvertNumberToWords: ConvertNumberToWordsInterface) {
    this.formGatewayInterface = FormGatewayInterface
    this.convertNumberToWords = ConvertNumberToWords
  }

  execute(blockId: string): string {
    const value = this.formGatewayInterface.getValueForBlockId(blockId)

    if (null !== value) {
      try {
        return this.convertNumberToWords.convert(Number(value))
      } catch (error) {
        return ''
      }
    }

    return ''
  }
}
