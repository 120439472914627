import React, {FunctionComponent} from 'react';

const CheckButton: FunctionComponent = () => {

  return (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.0001 7.77999L1.2201 4.99999L0.273438 5.93999L4.0001 9.66666L12.0001 1.66666L11.0601 0.726658L4.0001 7.77999Z"
        fill="#1BCC8C" />
    </svg>
  );
}

export default CheckButton;
