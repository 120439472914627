import React, {FunctionComponent} from 'react';

const Edit: FunctionComponent = () => {

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.8059 3.75417C14.0659 4.01417 14.0659 4.43417 13.8059 4.69417L12.5859 5.91417L10.0859 3.41417L11.3059 2.19417C11.4305 2.06933 11.5996 1.99918 11.7759 1.99918C11.9523 1.99918 12.1214 2.06933 12.2459 2.19417L13.8059 3.75417ZM1.99927 13.6675V11.6408C1.99927 11.5475 2.0326 11.4675 2.09927 11.4008L9.3726 4.12749L11.8726 6.62749L4.5926 13.9008C4.5326 13.9675 4.44593 14.0008 4.35927 14.0008H2.3326C2.14593 14.0008 1.99927 13.8542 1.99927 13.6675Z" fill="#119DA4"/>
    </svg>
  );
}

export default Edit;
