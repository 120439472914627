import React, {FunctionComponent} from 'react';

const Validate: FunctionComponent = () => {

  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.99991 11.17L1.82991 7L0.409912 8.41L5.99991 14L17.9999 2L16.5899 0.589996L5.99991 11.17Z" fill="#1BCC8C"/>
    </svg>
  );
}

export default Validate;
