import React, {FunctionComponent, useEffect, useState} from 'react'
import Button from '../../button/Button'

// Icon
import CheckButton from '../../svg/CheckButton'
import CommentButton from '../../svg/CommentButton'
import ValidateBlockUseCase from '../../../../domain/Validation/UseCase/ValidateBlockUseCase';
import FetchBlockGateway from '../../../../gateway/Block/FetchBlockGateway';
import Validate from '../../svg/Validate';
import Edit from '../../svg/Edit';
import CheckRounded from '../../svg/CheckRounded';
import NotValidate from '../../svg/NotValidate';
import GetValidationForBlockUseCase from '../../../../domain/Validation/UseCase/GetValidationForBlockUseCase';
import {useTranslation} from 'react-i18next';

type Props = {
  blockId: string
}

const ValidationComponent: FunctionComponent<Props> = ({blockId}) => {
  const { t } = useTranslation()

  const [state, setState] = useState<string>('')
  const [value, setValue] = useState<string>('')

  useEffect(() => {
    new GetValidationForBlockUseCase(new FetchBlockGateway()).execute(blockId).then(response => {
      setState(response.state)
      setValue(response.notes)
    })
  }, [blockId])

  async function handleOk() {
    await (new ValidateBlockUseCase(new FetchBlockGateway())).execute(blockId, 'validate', '-')
    setState('validate')
  }

  function handleComment() {
    setState('comment')
  }

  function handleChangeNote(event) {
    setValue(event.target.value)
  }

  function handleEdit() {
    setState('')
  }

  async function handleNotValidate() {
    await (new ValidateBlockUseCase(new FetchBlockGateway())).execute(blockId, 'not_validate', value)
    setState('not_validate')
  }

  return (
    <>
      <div className="card__second">
        <div className="card__validation">
          {(state === 'validate' &&
            <Validate />
          )}
          {(state === 'comment' || state === 'not_validate' &&
            <NotValidate />
          )}
        </div>
        <div className="card__separation"/>
      </div>
      <div className={`card__third ${state === 'comment' || state === 'not_validate' ? 'card__third--column' : ''}`}>
        {(state === 'validate' &&
          <Button classes="button button-primary--outline button--medium button--width-fit button-shadow-secondary"
                  label={t('validation.reassess')}
                  icon={<Edit />}
                  position="left"
                  onClick={handleEdit} />
        )}
        {(state === '' &&
          <>
            <Button classes="button button-success--outline button--medium button--width-fit button-shadow-secondary"
                    label={t('validation.ok')}
                    icon={<CheckButton />}
                    position="left"
                    onClick={handleOk} />
            <Button classes="button button-error--outline button--medium button--width-fit button-shadow-secondary"
                    label={t('validation.comment')}
                    icon={<CommentButton />}
                    position="left"
                    onClick={handleComment} />
          </>
        )}
        {(state === 'comment' &&
          <>
            <div className="form-floating u-mbb">
              <input type="text" id={`${blockId}_note`} className="form-control" placeholder="note" onChange={handleChangeNote} />
              <label htmlFor={`${blockId}_note`}>{t('validation.note')}</label>
            </div>
            <Button classes="button button-primary--outline button--medium button--width-fit button-shadow-secondary"
                    label={t('validation.resolved')}
                    icon={<CheckRounded />}
                    position="left"
                    onClick={handleNotValidate} />
          </>
        )}
        {(state === 'not_validate' &&
          <>
            <p>{value}</p>
            <Button classes="button button-primary--outline button--medium button--width-fit button-shadow-secondary"
                    label={t('validation.reassess')}
                    icon={<Edit />}
                    position="left"
                    onClick={handleEdit} />
          </>
        )}
      </div>
    </>
  )
}

export default ValidationComponent
