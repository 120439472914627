import {FC, InputHTMLAttributes, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';

import {IFormFieldProps} from '../../IFormFieldProps';
import Element from '../../../../domain/Element/Element';
import FieldErrorMessage from '../messages/FieldErrorMessage';
import FormGateway from '../../../../gateway/Form/FormGateway';
import ConvertNumberToWordsUseCase from '../../../../domain/Convert/UseCase/ConvertNumberToWordsUseCase';
import NumberToWordConverter from '../../../util/NumberToWordConverter';
import ValidationSchema from '../../../../validation/ValidationSchema';

import Copy from '../../svg/Copy';

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id?: string
  classes: string,
  value?: string,
  defaultValue?: string
  reference: string,
  timestamp: number|null,
  element: Element,
  register: any
}

const InputConvertNumberToWord: FC<IProps> = ({id, classes, label, value, defaultValue, register, error, required, reference, timestamp, element, ...rest}) => {
  const { t } = useTranslation()

  const [convertedString, setConvertedString] = useState<string>('')
  const [schema] = useState<Record<string, unknown>>(new ValidationSchema().create(element, (required === true)))

  useEffect(() => {
    setConvertedString(new ConvertNumberToWordsUseCase(new FormGateway(), new NumberToWordConverter()).execute(reference))
  }, [timestamp, reference]);

  return (
    <div className={`${classes}  ${rest.readonly && "readonly-element"}`}>

      <div className={`form-floating ${error && 'error'} `} title={rest.help}>

        <input className="form-control" value={value} defaultValue={defaultValue} readOnly={rest.readonly} {...rest}
               ref={register({
                 "required": schema.required,
                 "validate": v => v === convertedString || t('element.error.different-to-expected')
               })}
               required={required}
               name={id} />

        <label htmlFor={id}>{label}</label>

        {'' !== convertedString &&
          <div className="flex-container middle-xs no-compensation-xs">
            <span className="help">{convertedString}</span>
            <span className="u-mxs u-pointer" onClick={() => navigator.clipboard.writeText(convertedString)}><Copy /></span>
            <br/>
          </div>
        }

        {error?.message && <FieldErrorMessage message={error.message} />}

      </div>

    </div>

  )
};

export default InputConvertNumberToWord;
