import ReceiptDetailInterface from './ReceiptDetailInterface';

export default class ReceiptDetail implements ReceiptDetailInterface {
    id: string
    resource: string
    title: string

    constructor(id: string, resource: string, title: string) {
        this.id = id
        this.resource = resource
        this.title = title
    }
}
