import ReceiptDetailGatewayInterface from '../../domain/Receipt/ReceiptDetailGatewayInterface';
import ReceiptDetail from '../../domain/Receipt/ReceiptDetail';
import Caller from '../../domain/Api/Caller';
import envVariable from '../../presentation/util/envVariable';
import storage from '../../presentation/util/storage';

export default class FetchReceiptDetailGateway implements ReceiptDetailGatewayInterface {

  byReceiptId(receiptId: string): Promise<ReceiptDetail|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossiers/${storage.getFolderId()}/receipts/${receiptId}`, {})
      .then(response => {
        if (!response) {
          return null
        }

        return new ReceiptDetail(response.receipt.id, response.receipt.resource,response.receipt.title)
      }).catch(() => {
        return null
      })
  }

  removeById(receiptId: string): Promise<boolean> {
    return Caller.executeDelete(`${envVariable('REACT_APP_API_URL')}/dossiers/${storage.getFolderId()}/receipts/${receiptId}`, [])
      .then(response => {
        return response
      }).catch(() => {
        return false
      })
  }
}
