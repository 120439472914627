import BlockGatewayInterface from '../../domain/Block/BlockGatewayInterface';
import Block from '../../domain/Block/Block';
import Caller from "../../domain/Api/Caller";
import envVariable from "../../presentation/util/envVariable";
import store from '../../store/store';

export default class FetchBlockGateway implements BlockGatewayInterface {

  getBlocksByPageId(pageId: string): Promise<Block[] | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/${envVariable('REACT_APP_LOCALE')}/pages/${pageId}/blocks`, {})
      .then(data => {
        if (!data) {
          return null
        }

        return (data.blocks).map((item) => {
          return new Block(
            item.id,
            item.page_id,
            item.title,
            item.rank,
            item.help,
            item.confirmation,
            item.visible,
            item.condition,
            item.icon
          )
        })
      })
      ;
  }

  validate(blockId: string, state: string, notes: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const formString = localStorage.getItem('validate')
      const formObject = (formString === null) ? {} : JSON.parse(formString)

      // tmp
      const blocksString = store.getState().blocks.all
      const blocksObject = (blocksString) ? JSON.parse(blocksString) : null
      const nameBlock = (blocksObject).filter(block => block.id === blockId)

      const data = []
      data[blockId] = {
        'state': state,
        'notes': notes,
        'name' : nameBlock[0].title
      }

      Object.assign(formObject, data);
      localStorage.setItem('validate', JSON.stringify(formObject))
      resolve(true)
    }).then(response => response);
  }

  getStateValidation(blockId: string): Promise<{ state: string, notes: string }> {
    return new Promise<{ state: string, notes: string }>((resolve) => {
      const formString = localStorage.getItem('validate')
      const formObject = (formString === null) ? {} : JSON.parse(formString)

      if (formObject[blockId]) {
        resolve(formObject[blockId])
      }

      resolve({
        'state': '',
        'notes': ''
      })
    }).then(response => response);
  }
}
