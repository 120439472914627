import BlockGatewayInterface from '../../Block/BlockGatewayInterface';

export default class ValidateBlockUseCase
{
  public blockGateway

  constructor(BlockGateway: BlockGatewayInterface) {
    this.blockGateway = BlockGateway
  }

  async execute(blockId: string, state: string, notes: string): Promise<boolean> {
    return await this.blockGateway.validate(blockId, state, notes)
  }
}
