import {createSlice} from '@reduxjs/toolkit'

// Define a type for the slice state
interface WizzardStepState {
  pages: string | null,
  currentPageId: string | null,
  nextPageId: string | null
}

// Define the initial state using that type
const initialState: WizzardStepState = {
  pages        : null,
  currentPageId: null,
  nextPageId   : null
}

export const wizzardStepSlice = createSlice({
  name        : 'wizzardStep',
  initialState: initialState,
  reducers    : {
    setAllPages: (state, action) => {
      state.currentPageId = JSON.parse(action.payload)[0].id
      state.pages = action.payload
    },
    updateProgressValueById: (state, action) => {
      if (state.pages) {
        const pages = JSON.parse(state.pages)

        const newObjects = pages.map(page => {
          if (page.id === action.payload.id) {
            page.progress = action.payload.progress
          }

          return page
        })

        state.pages = JSON.stringify(newObjects)
      }
    },
    updateProgressValueByIdGoBack: (state) => {
      if (state.pages) {
        const pages = JSON.parse(state.pages)

        const currentPageIdIndex = pages.findIndex(page => page.id === state.currentPageId)
        pages[currentPageIdIndex].progress = 0
        pages[currentPageIdIndex - 1].progress = 0

        state.pages = JSON.stringify(pages)
      }
    },
    updateNextPageId: (state, action) => {
      if (state.pages) {
        const pages = JSON.parse(state.pages)

        const nextPageIndex = pages.findIndex(page => page.id === action.payload.id)
        state.currentPageId = pages[nextPageIndex].id
        state.nextPageId = (pages && pages[nextPageIndex + 1] && pages[nextPageIndex + 1].id) ? pages[nextPageIndex + 1].id : null
      }
    },
    updateNextPageIdAndCurrentPageIddByGoBack: (state, action) => {
      if (state.pages) {
        const pages = JSON.parse(state.pages)
        const nextPageIndex = pages.findIndex(page => page.id === action.payload.id)

        state.currentPageId = (pages && pages[nextPageIndex - 1].id) ? pages[nextPageIndex - 1].id : null
        state.nextPageId = (pages && pages[nextPageIndex].id) ? pages[nextPageIndex].id : null
      }
    }
  }
})

export const {
  setAllPages,
  updateProgressValueById,
  updateProgressValueByIdGoBack,
  updateNextPageId,
  updateNextPageIdAndCurrentPageIddByGoBack
} = wizzardStepSlice.actions

export default wizzardStepSlice.reducer
