import {FC, FormEvent, InputHTMLAttributes, useEffect, useState} from 'react'
import {IFormFieldProps} from '../../IFormFieldProps';
import {ReactSortable} from "react-sortablejs";
import UnFold from "../../svg/UnFold";
import {IItemType} from './IItemType';

interface Ipros extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
    id?: string,
    classes: string,
    options: IItemType[],
    value?: string,
    defaultValue?: Array<string>,
    onChange?: (e: FormEvent<HTMLInputElement>) => void,
}

const Sort: FC<Ipros> = ({id, name, classes, options, label, onChange, defaultValue, register, ...rest}) => {
    const [items, setItems] = useState<IItemType[]>([]);

    options.map((option, index) => {
        options[index].id = option.value;
        options[index].name = option.label;
    })

    useEffect(() => {
        if (defaultValue) {
            const SortedOptions: IItemType[] = [];
            defaultValue?.map((value, index) => {
                options.map((option) => {
                    if (option.value === value) {
                        SortedOptions[index] = option;
                    }
                })
            })
            setItems(SortedOptions);
        } else {
            setItems(options);
        }
    }, [options, defaultValue])

    return (
        <div className={`sortable ${classes} ${rest.readonly && "readonly-element"}`} title={rest.help}>
            <p className={`u-txt-size-l u-txt-color-text-light`}>{label}</p>
            {
                items.map((item, index) => {
                    return <input readOnly={true} onChange={onChange} style={{display: 'none'}} ref={register} key={`${name}[${index}]`} type="text" name={`${id}[${index}]`} value={item.value}/>
                })
            }
            <ReactSortable list={items} setList={setItems} >
                {items.map((item) => (
                  <div className="col-md-12" key={`sortable-${item.value}`}>
                      <label className="sortable-label">
                          <UnFold />
                          <span>{item.name}</span>
                      </label>
                  </div>
                ))}
            </ReactSortable>
        </div>
    )
}

export default Sort;
