import React, {FunctionComponent} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import logo from '../../../assets/images/logo-min.png'

// Svg
import Back from '../svg/Back'
import NotAllowed from '../svg/NotAllowed'
import Logout from '../svg/Logout'

import Button from '../button/Button'

type Props = {
  reference?: number,
  cta?: boolean
}

const HeaderComponent: FunctionComponent<Props> = ({reference, cta}) => {
  const { t } = useTranslation()

  return (
    <div className="main-header u-mxl">
      <div className="flex-container middle-xs no-compensation">
        <h1 className="u-mb0 u-mrl">
          <a href="#">
            <img src={logo} alt="Société d'investissement" style={{maxWidth: '150px'}} />
          </a>
        </h1>
        <Link to="/tableau-de-bord" className="back">
          <Back color="var(--ink-1)" />
          <span className="u-txt-size-l u-txt-color-ink-1 u-mxs">{t('button.back-home')}</span>
        </Link>
      </div>
      <div className="flex-container middle-xs center-xs">
        {reference && <span className="u-txt-size-l">Dossier N° 8765HGF</span>}
      </div>
      <div className="flex-container middle-xs center-xs">
        <div className="flex-container middle-xs center-xs">
          {cta &&
          <Button classes="button button-error button--medium button--width-fit" label="Refus immediat" position="right"
                  icon={<NotAllowed />} />}
        </div>
      </div>
      <div className="flex-container middle-xs center-xs">
        <Link to="/logout" className="button button-secondary button--medium button--width-fit u-pxm">{t('button.logout')} <Logout/></Link>
      </div>
    </div>
  );
}

export default HeaderComponent
