import * as React from 'react';
import {FC, useEffect, useState} from 'react';

import {IDatalistChoiceProps} from './IDatalistChoiceProps';
import FieldErrorMessage from '../messages/FieldErrorMessage';
import Loader from '../../loader/Loader';
import GetChoices from '../../../../useCase/Datalist/GetChoices';
import FetchDatalistGateway from '../../../../gateway/Datalist/FetchDatalistGateway';
import {OptionsAttributesInterface} from '../../../../domain/Options/OptionsInterface';
import GetChoicePresentation from '../../../../useCase/Datalist/GetChoicePresentation';

const DataListChoice: FC<IDatalistChoiceProps> = ({options, id, datalistId, defaultValue, classes, register, name, error, label, ...rest}) => {
  const [optionsFilter, setOptionsFilter] = useState<OptionsAttributesInterface[]|null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [presentation, setPresentation] = useState<string>('');
  const [value, setValue] = useState<string|undefined>(defaultValue);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (defaultValue) {
      new GetChoicePresentation(new FetchDatalistGateway()).execute(datalistId, defaultValue).then(response => {
        setPresentation(response)
      })
    }
  }, [defaultValue])

  const handleChange = (e) => {
    const value = e.target.value
    setPresentation(value);
    setValue('');

    if (value.length < 3) {
      setOptionsFilter([])
      setShow(false)
      return false
    }

    setShow(true)
    setLoading(true)

    new GetChoices(new FetchDatalistGateway()).execute(datalistId, value).then(response => {
      setLoading(false)

      if (undefined !== response?.options) {
        setOptionsFilter(response?.options)
      }
    })
  }

  const handleClickDatalist = (e) => {
    const value = e.target.dataset.value
    const label = e.target.dataset.label

    setValue(value)
    setPresentation(label)
    setShow(false)
  }

  return (
    <div className={`${classes}  ${rest.readonly && "readonly-element"}`}>
      <input className={'checkbox-toggle'} id={id} name={id} ref={register} value={value} />

      <div className={`form-floating ${error ? 'error' : ''}`} title={rest.help}>
        <input className="form-control"
               type="text"
               id={name}
               name={name}
               placeholder={name}
               value={presentation}
               autoComplete="new-password"
               onChange={handleChange}
        />
        <label htmlFor={name}>{label}</label>
      </div>

      {(show &&
      <div className={`datalist-choice ${(optionsFilter && optionsFilter.length === 0 ? 'datalist-choice--loading' : '')}`}>
        {(optionsFilter && optionsFilter.length > 0 &&
          optionsFilter?.map(option => {
            return <div key={option.value} className="datalist-choice__item" data-value={option.value} data-label={option.label} onClick={handleClickDatalist}>{option.label}</div>
          })
        )}
        {(isLoading &&
          <Loader />
        )}
      </div>
      )}

      {error?.message && <FieldErrorMessage message={error.message} />}

    </div>

  )
};

export default DataListChoice;
