import ReceiptDetailGatewayInterface from '../../domain/Receipt/ReceiptDetailGatewayInterface';

export default class PreviewReceiptUseCase
{
  public receiptDetailGateway;

  constructor(ReceiptDetailGateway: ReceiptDetailGatewayInterface) {
    this.receiptDetailGateway = ReceiptDetailGateway;
  }

  execute(receiptId: string): Promise<{ resource: string, title: string }> {
    return this.receiptDetailGateway.byReceiptId(receiptId).then(receiptDetail => {
      return {
        resource: receiptDetail.resource,
        title: receiptDetail.title,
      }
    })
  }
}
