import * as React from "react";
import {FC} from 'react'
import {useAppDispatch, useAppSelector} from '../../../../store/hook';

import Element from '../../../../domain/Element/Element';
import {EElementType} from "../../../../domain/Element/EElementType";
import FormGateway from '../../../../gateway/Form/FormGateway';
import CalculateUseCase from '../../../../domain/Calculate/UseCase/CalculateUseCase';
import SaveUseCase from '../../../../domain/Form/SaveUseCase';
import getElementsIdToRender from '../../../util/getElementsIdToRender';
import {updateElementTimestamp} from '../../../../store/element/elements';

import Input from "../input/Input";
import CurrencyAmountInput from "../input/CurrencyAmountInput";
import PercentageInputInteger from "../percentage/PercentageInputInteger";
import PercentageInputRange from "../percentage/PercentageInputRange";

interface IProps {
    element: Element
    register: any
    clearErrors: any
    formData: Record<string, unknown> | []
}

/**
 *
 * @param element
 * @param register
 * @param clearErrors
 * @param formData
 * @constructor
 */
const Calculate: FC<IProps> = (
    {
        element,
        register,
        clearErrors,
        formData
    }
) => {
    const dispatch = useAppDispatch()

    const elementsWithReferenceJSON = useAppSelector(state => state.elements.currentElementsWithReference)
    const elementsWithReference: Element[] = JSON.parse(elementsWithReferenceJSON)

    const formGateway = new FormGateway()

    const calculateUseCase = new CalculateUseCase(formGateway)
    const value = calculateUseCase.execute(element.calculate) ?? ''

    const response = formGateway.getCurrentValueForFormId()

    if (undefined == response.values[element.id] || value !== response.values[element.id]) {
        const data = [];
        data[element.id] = value;
        const saveUseCase = new SaveUseCase(formGateway)
        saveUseCase.execute(data).then(
            () => {
                const elementsIdToRender: string[] = getElementsIdToRender(
                    null,
                    element.id,
                    null,
                    elementsWithReference
                )
                elementsIdToRender.map(
                    (elementIdToRender) => dispatch(updateElementTimestamp({'id': elementIdToRender}))
                )
            }
        )
    }

    const attributes = element.attributes

    switch (element.type) {
        case EElementType.INPUT_PERCENTAGE_INTEGER:
            return <PercentageInputInteger
                element={element}
                formData={formData}
                register={register}
                clearErrors={clearErrors}
                required={attributes?.required}
                value={value.toString()}
            />
        case EElementType.INPUT_PERCENTAGE_RANGE:
            return <PercentageInputRange
                element={element}
                formData={formData}
                register={register}
                value={value.toString()}
            />
        case EElementType.INPUT_FLOAT_CURRENCY_AMOUNT:
            return <CurrencyAmountInput
                id={element.id}
                label={attributes?.label}
                register={register}
                name={element.name}
                help={attributes?.help}
                placeholder={attributes?.label}
                required={attributes?.required}
                readonly={attributes?.readonly}
                clearErrors={clearErrors}
                classes={element.cssClasses}
                type={'number'}
                step={0.01}
                value={value.toString()}
            />
        case EElementType.INPUT_INTEGER:
            return <Input
                id={element.id}
                label={attributes?.label}
                register={register}
                name={element.name}
                help={attributes?.help}
                placeholder={attributes?.label}
                required={attributes?.required}
                readonly={attributes?.readonly}
                clearErrors={clearErrors}
                classes={element.cssClasses}
                type={'number'}
                pattern='[0-9]*'
                value={value.toString()}
            />
        case EElementType.INPUT_FLOAT:
            return <Input
                id={element.id}
                label={attributes?.label}
                register={register}
                name={element.name}
                help={attributes?.help}
                placeholder={attributes?.label}
                required={attributes?.required}
                readonly={attributes?.readonly}
                clearErrors={clearErrors}
                classes={element.cssClasses}
                type={'number'}
                step={0.01}
                value={value.toString()}
            />
        case EElementType.INPUT_TEXT:
        default:
            return <Input
                id={element.id}
                label={attributes?.label}
                register={register}
                name={element.name}
                help={attributes?.help}
                placeholder={attributes?.label}
                required={attributes?.required}
                readonly={attributes?.readonly}
                clearErrors={clearErrors}
                classes={element.cssClasses}
                type={'text'}
                value={value.toString()}
            />
    }

};

export default Calculate;
