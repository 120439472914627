import React, {FunctionComponent, InputHTMLAttributes, useState, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';

import {IFormFieldProps} from '../../IFormFieldProps';
import MyUploaderHeader from './MyUploaderHeader';
import Layout from './Layout';
import IconFactory from '../../svg/IconFactory';
import {useTranslation} from 'react-i18next';
import SendDocumentUseCase from '../../../../domain/Document/UseCase/SendDocumentUseCase';
import FetchDocumentGateway from '../../../../gateway/Document/FetchDocumentGateway';
import Document from '../../../../domain/Document/Document';
import Converter from '../../../util/Converter';
import LocalStorageGateway from '../../../../gateway/Form/LocalStorageGateway';
import Receipt from '../../../../domain/Receipt/Receipt';
import PreviewReceiptUseCase from '../../../../useCase/Receipt/PreviewReceiptUseCase';
import FetchReceiptDetailGateway from '../../../../gateway/Receipt/FetchReceiptDetailGateway';
import FieldErrorMessage from '../messages/FieldErrorMessage';
import RemoveReceiptUseCase from '../../../../useCase/Receipt/RemoveReceiptUseCase';
import LocalStorageReceiptGateway from '../../../../gateway/Receipt/LocalStorageReceiptGateway';

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  classes?: string,
  label?: string,
  help?: string,
  icon?: {
    type?: string,
    label?: string
  },
  id: string
  blockId: string
  typeId: string
  receiptsDefault: Receipt[]|null
  presentationTmp?: string
  register: any
  clearErrors: any
  error: any
}

const MyUploader: FunctionComponent<IProps> = ({classes, label, help, icon, id, blockId, typeId, receiptsDefault, presentationTmp, register, clearErrors, error}) => {
  const {t} = useTranslation()

  const [receipts, setReceipts] = useState<Receipt[]|null>(receiptsDefault)

  const onDrop = useCallback(acceptedFiles => {
    clearErrors(id)

    const promises: Promise<any>[] = []
    acceptedFiles.map(file => {
      promises.push(new Converter().toBase64(file).then(base64 => {
        if (typeof base64 === 'string') {
          return new Document(typeId, id, base64, file.name)
        }
      }))
    })

    Promise.all(promises).then(documents => {
      if (documents.length > 0) {
        new SendDocumentUseCase(new FetchDocumentGateway(), new LocalStorageGateway()).execute(blockId, documents).then(response => {
          const newReceipts = response?.filter(receipt => receipt.elementId === id)
          setReceipts((newReceipts) ?? null)
        })
      }
    })

  }, [blockId, id, typeId])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop
  })

  const removeFile = receiptToRemove => () => {
    new RemoveReceiptUseCase(new FetchReceiptDetailGateway(), new LocalStorageReceiptGateway(), new LocalStorageGateway()).execute(receiptToRemove).then(response => {
      if (response) {
        const newReceipts = receipts?.filter(receipt => receipt !== receiptToRemove)
        setReceipts((newReceipts) ?? null)
      }
    })
  }

  const show = receiptToShow => () => {
    new PreviewReceiptUseCase(new FetchReceiptDetailGateway()).execute(receiptToShow.receiptId).then(response => {
      const blob = new Converter().dataURItoBlob(response.resource)
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = response.title;
      a.click();
      window.URL.revokeObjectURL(url);
    })
  }

  return (
    <>
      <MyUploaderHeader classes={classes}
                        label={label}
                        help={help} />
      <div {...getRootProps()} className={`dzu-dropzone ${isDragActive ? 'dzu-dropzoneActive' : ''}`}>
        <input {...getInputProps()} id={id} />

        <Layout icon={<IconFactory type={icon?.type} />}
                iconLabel={presentationTmp} />
      </div>

      {error?.message && <FieldErrorMessage message={error.message} />}

      {(receipts &&
        (receipts.map(receipt => (
            (id === receipt.elementId &&
              <div className="dzu-previewContainer" key={receipt.elementId}>
                <div className="upload-progress__wrapper">
                  <div className="button button-primary--outline button--small button--width-fit button--height-fit"
                       onClick={show(receipt)}>{receipt.title}</div>
                  <div className="button button-error--outline button--small button--width-fit button--height-fit"
                       onClick={removeFile(receipt)}>{t('button.remove')}
                  </div>
                </div>
              </div>
            )
          ))
        )
      )}
    </>
  )
}

export default MyUploader;



