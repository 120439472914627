import React, {FunctionComponent} from 'react';

const CheckRounded: FunctionComponent = () => {

  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8.49992 1.33334C4.81992 1.33334 1.83325 4.32001 1.83325 8.00001C1.83325 11.68 4.81992 14.6667 8.49992 14.6667C12.1799 14.6667 15.1666 11.68 15.1666 8.00001C15.1666 4.32001 12.1799 1.33334 8.49992 1.33334ZM8.49992 13.3333C5.55992 13.3333 3.16659 10.94 3.16659 8.00001C3.16659 5.06001 5.55992 2.66668 8.49992 2.66668C11.4399 2.66668 13.8333 5.06001 13.8333 8.00001C13.8333 10.94 11.4399 13.3333 8.49992 13.3333ZM7.16659 9.44668L11.0866 5.52668C11.3466 5.26668 11.7733 5.26668 12.0333 5.52668C12.2933 5.78668 12.2933 6.20668 12.0333 6.46668L7.63993 10.86C7.37993 11.12 6.95993 11.12 6.69992 10.86L4.97326 9.13334C4.71326 8.87334 4.71326 8.45334 4.97326 8.19334C5.09781 8.06851 5.26691 7.99835 5.44326 7.99835C5.6196 7.99835 5.7887 8.06851 5.91326 8.19334L7.16659 9.44668Z"
              fill="#119DA4" />
      </g>
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fillRule="evenodd" clipRule="evenodd"
                d="M8.49992 1.33334C4.81992 1.33334 1.83325 4.32001 1.83325 8.00001C1.83325 11.68 4.81992 14.6667 8.49992 14.6667C12.1799 14.6667 15.1666 11.68 15.1666 8.00001C15.1666 4.32001 12.1799 1.33334 8.49992 1.33334ZM8.49992 13.3333C5.55992 13.3333 3.16659 10.94 3.16659 8.00001C3.16659 5.06001 5.55992 2.66668 8.49992 2.66668C11.4399 2.66668 13.8333 5.06001 13.8333 8.00001C13.8333 10.94 11.4399 13.3333 8.49992 13.3333ZM7.16659 9.44668L11.0866 5.52668C11.3466 5.26668 11.7733 5.26668 12.0333 5.52668C12.2933 5.78668 12.2933 6.20668 12.0333 6.46668L7.63993 10.86C7.37993 11.12 6.95993 11.12 6.69992 10.86L4.97326 9.13334C4.71326 8.87334 4.71326 8.45334 4.97326 8.19334C5.09781 8.06851 5.26691 7.99835 5.44326 7.99835C5.6196 7.99835 5.7887 8.06851 5.91326 8.19334L7.16659 9.44668Z"
                fill="#119DA4" />
        </g>
      </svg>
    </svg>
  );
}

export default CheckRounded;
