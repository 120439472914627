import React, {FunctionComponent, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import HeaderLightComponent from '../component/header/HeaderLightComponent';
import FooterComponent from '../component/footer/FooterComponent';
import storage from '../util/storage';
import CreateUseCase from '../../useCase/Folder/CreateUseCase';
import FetchFolderGateway from '../../gateway/Folder/FetchFolderGateway';

import SignedFiles from '../component/svg/SignedFiles';
import SignatureFiles from '../component/svg/SignatureFiles';
import CurrentCases from '../component/svg/CurrentCases';
import AbandonedFiles from '../component/svg/AbandonedFiles';

import '../../assets/styles/components/_dashboard.scss'

const Dashboard: FunctionComponent = () => {
  const {t} = useTranslation()
  const history = useHistory()

  const [role] = useState<string>(localStorage.getItem('role') ?? '');
  const [signedFiles, setSignedFiles] = useState<number>(0);
  const [signatureFiles, setSignatureFiles] = useState<number>(0);
  const [currentCases, setCurrentCases] = useState<number>(0);
  const [abandonedFiles, setAbandonedFiles] = useState<number>(0);

  useEffect(() => {
    (new FetchFolderGateway()).getFolders().then(folders => {
        if (null !== folders) {
          setSignedFiles((folders.filter(folder => folder.status === 'signed')).length)
          setSignatureFiles((folders.filter(folder => folder.status === 'signature')).length)
          setCurrentCases((folders.filter(folder => (folder.status === 'ongoing' || folder.status === 'mission_validated' || folder.status === 'mission_validation' || folder.status === 'adequacy_report_validated' || folder.status === 'adequacy_report_validation'))).length)
          setAbandonedFiles((folders.filter(folder => (folder.status === 'canceled' || folder.status === 'rejected'))).length)
        }
      }
    )
  }, [])

  const createUseCase = new CreateUseCase(new FetchFolderGateway());

  function createFileWithoutClient() {
    createUseCase.execute(storage.getFormId(), null)
      .then(() => {
        history.push(`/form/`)
      })
  }

  return <>
    <HeaderLightComponent />
    <>
      <div className="wrap u-mxAuto">
        <div className="flex-container">
          <div className="col-md-12">
            <h2 className="h3 help-wrapper u-mbs">{t('dashboard.title')}</h2>
          </div>
        </div>
      </div>
      <div className="box-elevations box-elevations--base box-elevations--max-heigt-initial box-elevations--mb u-mxAuto">
        <div className="flex-container">
          {role !== 'client' && role !== 'prospect_direct' &&
          <>
            <div className="col-md-6">
              <Link to={'/creer-un-dossier-client'}
                    className="create-folder button button-primary button--large button--width-full">
                {t('folder.create-customer')}
              </Link>
            </div>
            <div className="col-md-6">
              <a href="#" onClick={() => createFileWithoutClient()}
                 className="create-folder-prospect button button-primary button--large button--width-full">{t('folder.create-lead')}</a>
            </div>
          </>
          }
          {role == 'prospect_direct' &&
              <>
                <div className="col-md-12">
                  <a href="#" onClick={() => createFileWithoutClient()}
                    className="create-folder-prospect button button-primary button--large button--width-full">{t('folder.create-folder')}</a>
              </div>
            </>
          }
        </div>
        <div className="dashboard__filters">
          <Link to={`/dossier/signed-files`} className="dashboard__filter">
            <div className="filter__icon"><SignedFiles color="var(--ink-1)"/></div>
            <div className="filter__number">{signedFiles}</div>
            <div className="filter__label">{t('dashboard.signed-files')}</div>
          </Link>
          <Link to={`/dossier/signature-files`} className="dashboard__filter">
            <div className="filter__icon"><SignatureFiles color="var(--ink-1)"/></div>
            <div className="filter__number">{signatureFiles}</div>
            <div className="filter__label">{t('dashboard.signature-files')}</div>
          </Link>
          <Link to={`/dossier/current-cases`} className="dashboard__filter">
            <div className="filter__icon"><CurrentCases color="var(--ink-1)"/></div>
            <div className="filter__number">{currentCases}</div>
            <div className="filter__label">{t('dashboard.current-cases')}</div>
          </Link>
          <Link to={`/dossier/abandoned-files`} className="dashboard__filter">
            <div className="filter__icon"><AbandonedFiles color="var(--ink-1)"/></div>
            <div className="filter__number">{abandonedFiles}</div>
            <div className="filter__label">{t('dashboard.abandoned-files')}</div>
          </Link>
        </div>
      </div>
      </>
    <FooterComponent />
  </>
}

export default Dashboard;
