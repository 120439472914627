import React, {FunctionComponent} from 'react';

const CommentButton: FunctionComponent = () => {

  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3333 1.83333H2.66659C1.93325 1.83333 1.33992 2.43333 1.33992 3.16666L1.33325 15.1667L3.99992 12.5H13.3333C14.0666 12.5 14.6666 11.9 14.6666 11.1667V3.16666C14.6666 2.43333 14.0666 1.83333 13.3333 1.83333ZM3.99992 6.5H11.9999V7.83333H3.99992V6.5ZM9.33325 9.83333H3.99992V8.5H9.33325V9.83333ZM11.9999 5.83333H3.99992V4.49999H11.9999V5.83333Z"
        fill="#F25151" />
      <defs>
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
      </defs>
    </svg>
  );
}

export default CommentButton;
